<template>
  <div class="content">
    <div class="head_title">隐私政策</div>
    <div v-html="policy"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      policy: ""
    }
  },
	created(){
		this.loadPolicy()
	},
  methods: {
		//加载用户协议
		async loadPolicy(){
			const {data:res} = await this.$publicHttp.get("softwareinfo")
      if(res.meta.status !== "success") return this.$message.error("获取最新隐私政策失败")
      this.policy = res.data.softwareInfo.privacyPolicy
		}
  },
}
</script>

<style lang="less" scoped>
  .content{
    padding: 10px 20px;
  }
  .head_title{
    color: #333333;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  .btns_area{
    margin: 20px auto;
    text-align: center;
  }
</style>